import React, { useEffect, useState } from "react";
import Footer from "../components/footer"
import Header from "../components/header"
import { MdLocationOn, MdOutlineArrowForwardIos, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { FaAngleDoubleDown, FaBusAlt } from "react-icons/fa";
import CloseIcon from '@mui/icons-material/Close';
import { GiSteeringWheel } from "react-icons/gi";
import Tooltip from '@mui/material/Tooltip';
import { Alert, AlertTitle, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, selectClasses, TextField } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { IoStarSharp } from "react-icons/io5";
import {
    Box,
    Tab,
    Tabs,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    Paper
} from '@mui/material';
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
const BusList = (seat) => {
    const location = useLocation();
    const { from, to, formattedDate } = location.state || { to: '', from: '', formattedDate: '' };
    const dirIcon = process.env.PUBLIC_URL + 'assets/images/direction.png';
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedboadingValue, setSelectedBoadingValue] = useState({});
    const [selecteddropingValue, setSelectedDropingValue] = useState({});
    const [gender, setGender] = useState([])
    const [errors, setErrors] = useState({});
    const [selectedBusId, setSelectedBusId] = useState(null);
    const [timer, setTimer] = useState(600); // 600 seconds = 10 minutes
    const [timerActive, setTimerActive] = useState(false);
    const [busWisePickupDropPoints, setBusWisePickupDropPoints] = useState([])
    const [selectedUpperSeats, setSelectedUpperSeats] = useState([]);
    const [selectedLowerSeats, setSelectedLowerSeats] = useState([]);
    const [busList, setBusList] = useState([]);
    const [imageSrcLower, setImageSrcLower] = useState({})
    const [open, setOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [amenitiesOpen, setAmenitiesOpen] = useState(false)
    const [bookingPolicies, setBookingPolicies] = useState(false)
    const [reviewOpen, setReviewOpen] = useState(false)
    const [busTypeID, setBusTypeID] = useState()
    const [busID, setBusID] = useState()
    const [totalPrice, setTotalPrice] = useState(0);
    const [imageSrcUpper, setImageSrcUpper] = useState({})
    const [departureTime, setDepartureTime] = useState(['Before 6 am', '6 am to 12 pm', '12 pm to 6 pm', 'After 6 pm'])
    // const [busType, setBusType] = useState(['AC', 'NON-AC', 'AC seater', 'AC sleeper', 'AC semi-sleeper'])
    // const [pickUpTime, setPickUpTime] = useState(['Morning', 'Afernoon', 'Evening', 'Night'])
    // const [dropofTime, setDropofTime] = useState(['Morning', 'Afernoon', 'Evening', 'Night'])
    const [operatorRating, setOperatorrating] = useState([5, 4, 3, 2, 1])
    const seatEmpty = process.env.PUBLIC_URL + 'assets/images/imgpsh_fullsize_anim (3).png';
    const seatBlack = process.env.PUBLIC_URL + 'assets/images/seat-black.png';
    const seatPink = process.env.PUBLIC_URL + 'assets/images/seat-pink.png';
    const seatBlue = process.env.PUBLIC_URL + 'assets/images/seat purpule.png';
    const history = useHistory()
    const [showSeats, setShowSeats] = useState(false);
    const [value, setValue] = useState(0);
    const [selectedLowerSeat, setSelectedLowerSeat] = useState([]);
    const [selectedLowerSeatPrice, setSelectedLowerSeatPrice] = useState([]);
    const [selectedUpperSeat, setSelectedUpperSeat] = useState([]);
    const [selectedUpperSeatPrice, setSelectedUpperSeatPrice] = useState([]);
    const [selectedAmenitiesBusId, setSelectedAmenitiesBusId] = useState(null);
    const [selectedReviewBusId, setSelectedReviewBusId] = useState(null);
    const [selectedPoliciesBusId, setSelectedPoliciesBusId] = useState(null);
    const [selectedSeatBusId, setSelectedSeatBusId] = useState(null);
    const [busTypeImages, setBusTypeImages] = useState([])
    const [amenities, setAmenities] = useState([])
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [selectedBusTypeAc, setSelectedBusTypeAc] = useState([]);
    const [selectedBusType, setSelectedBusType] = useState([]);
    const [busAcType, setBusActype] = useState([])
    const [busType, setBusType] = useState([])
    const handleSelectBoadingPoint = (event) => {
        const selectedPoint = busWisePickupDropPoints?.boarding_array?.find(point => point.boarding_id === event.target.value);
        setSelectedBoadingValue(selectedPoint);
        setValue(1);
        console.log(selectedPoint);
    };

    const handleSelectDropingPoint = (event) => {
        const selectedPoint = busWisePickupDropPoints?.droping_array?.find(point => point.droping_id === event.target.value);
        setSelectedDropingValue(selectedPoint);
        console.log(selectedPoint);
    };

    useEffect(() => {
        busFilterData()
    }, [])
    useEffect(() => {
        searchwisebusList(formattedDate);
    }, [from?.id, to?.id, formattedDate, selectedUpperSeat, selectedLowerSeat])

    const handleDateChange = (date) => {
        setSelectedDate(date);
        const formattedDate = date ? date.toLocaleDateString('en-GB') : '';
        console.log(formattedDate);
    };

    const searchwisebusList = async (formattedDate) => {
        let data = new FormData();
        data.append('boarding_point', from?.id)
        data.append('droping_point', to?.id)
        data.append('date', formattedDate)
        const params = {
            'boarding_point': from?.id,
            'droping_point': to?.id,
            'date': formattedDate
        }
        try {
            await axios.post("search_bus", data, {
                params: params
            }).then((res) => {
                console.log(res.data.data);
                setBusList(res.data.data)
                setOpenDialog(false)
            })
        }
        catch (error) {
            toast.error(error.data.message);
        }
    }

    const busLayoutAPI = async (busId) => {
        let data = new FormData();
        data.append('bus_id', busId)
        data.append('booking_date', formattedDate)
        try {
            await axios.post("bus_layout", data, {
            }).then((res) => {
                if (res.data.success == true) {
                    setBusLayoutData(res.data.data)
                } else {
                    toast.error(res.data.message || 'Invalid Message');
                }
            })
        }
        catch (res) {
            toast.error(res.data.message);
        }
    }

    const BusWiseBoardingDroppingPoints = async (busId, main_boarding_point_id, main_droping_point_id) => {
        console.log("132132", main_boarding_point_id);
        console.log("52131", main_droping_point_id);
        console.log("52131", busId);

        let data = new FormData();
        data.append('bus_id', busId)
        data.append('boarding_point_id', main_boarding_point_id)
        data.append('droping_point_id', main_droping_point_id)
        try {
            await axios.post("boarding_dropping_points", data, {
            }).then((res) => {
                if (res.data.success == true) {
                    setBusWisePickupDropPoints(res.data.data)

                    // toast.success(res.data.message);
                } else {
                    toast.error(res.data.message || 'Invalid Message');
                }
            })
        }
        catch (res) {
            toast.error(res.data.message);
        }
    }
    const busFilterData = async () => {
        let data = new FormData();

        try {
            await axios.post("bus_filter_list", data, {
            }).then((res) => {
                console.log(res.data.data);
                setAmenities(res.data.data?.amenities)
                setBusActype(res.data.data?.bus_ac_types)
                setBusType(res.data.data?.bus_types)
                // setBusActype(res.data.data?.amenities)
            })
        }
        catch (error) {
            toast.error(error.data.message);
        }
    }

    // const busTypeImages = [
    //     'assets/icons/snow.svg',
    //     'assets/icons/no-snow.svg',
    //     'assets/icons/seater.svg',
    //     'assets/icons/sleeper.svg',
    //     'assets/icons/semi-sleeper.svg',
    // ];
    // const dropofTimeImages = [
    //     'assets/icons/earlymorning.svg',
    //     'assets/icons/morning.svg',
    //     'assets/icons/earlymorning.svg',
    //     'assets/icons/earlymorning.svg',
    // ];
    const opratorRatingImages = [
        'assets/icons/star icon.svg',
        'assets/icons/star icon.svg',
        'assets/icons/star icon.svg',
        'assets/icons/star icon.svg',
        'assets/icons/star icon.svg',
    ];

    const updateTotalPrice = (newSelectedSeats) => {
        const newTotalPrice = newSelectedSeats.reduce((acc, seatNumber) => {
            const seat = findSeatByNumber(seatNumber);
            return parseFloat(acc) + parseFloat(seat ? seat.seat_price : 0);
        }, 0);
        setTotalPrice(newTotalPrice);
    };

    const findSeatByNumber = (seatNumber) => {
        // Combine lower and upper layouts into a single array
        const combinedLayouts = [
            ...busLayoutData.BusLayoutData[0].lower_layout,
            ...busLayoutData.BusLayoutData[0].upper_layout
        ];

        // Search for the seat in the combined layouts
        for (let row of combinedLayouts) {
            for (let seat of row) {
                if (seat.seat_number === seatNumber) {
                    return seat;
                }
            }
        }
        return null;
    };

    const getLowwerSeatPrices = (selectedSeats) => {
        const seatPrices = selectedSeats.map(seatNumber => {
            for (const row of busLayoutData?.BusLayoutData[0].lower_layout) {
                const seat = row.find(seat => seat.seat_number === seatNumber);
                if (seat) {
                    return parseInt(seat.seat_price, 10);
                }
            }
            return 0;
        });
        return seatPrices;
    };

    const getUpperSeatPrices = (selectedSeats) => {
        const seatPrices = selectedSeats.map(seatNumber => {
            for (const row of busLayoutData?.BusLayoutData[0].upper_layout) {
                const seat = row.find(seat => seat.seat_number === seatNumber);
                if (seat) {
                    return parseInt(seat.seat_price, 10);
                }
            }
            return 0;
        });
        return seatPrices;
    };


    const handleClickLowerSeat = (seatNumber) => {
        setSelectedLowerSeats(prevSelectedSeats => {
            const isSelected = prevSelectedSeats.includes(seatNumber);
            const newSelectedSeats = isSelected
                ? prevSelectedSeats.filter(seat => seat !== seatNumber)
                : [...prevSelectedSeats, seatNumber];

            // Syncing with selectedSeat
            const allSelectedSeats = [...newSelectedSeats, ...selectedUpperSeats];
            setSelectedLowerSeat(newSelectedSeats);
            updateTotalPrice(allSelectedSeats);

            setImageSrcLower(prevImageSrc => ({
                ...prevImageSrc,
                [seatNumber]: isSelected ? seatEmpty : seatBlue
            }));

            const seatPrices = getLowwerSeatPrices(newSelectedSeats);
            setSelectedLowerSeatPrice(seatPrices);

            console.log("Selected seats:", selectedLowerSeats);
            console.log("Selected seats lower Price:", selectedLowerSeatPrice);
            return newSelectedSeats;
        });
    };

    const handleClickUpperSeat = (seatNumber) => {

        setSelectedUpperSeats(prevSelectedSeats => {
            const isSelected = prevSelectedSeats.includes(seatNumber);
            const newSelectedSeats = isSelected
                ? prevSelectedSeats.filter(seat => seat !== seatNumber)
                : [...prevSelectedSeats, seatNumber];
            const allSelectedSeats = [...selectedLowerSeats, ...newSelectedSeats];
            setSelectedUpperSeat(newSelectedSeats);
            updateTotalPrice(allSelectedSeats);

            setImageSrcUpper(prevImageSrc => ({
                ...prevImageSrc,
                [seatNumber]: isSelected ? seatEmpty : seatBlue
            }));

            const seatPrices = getUpperSeatPrices(newSelectedSeats);
            setSelectedUpperSeatPrice(seatPrices);

            console.log("Selected seats:", selectedUpperSeats);
            console.log("Selected upper seats price:", selectedUpperSeatPrice);
            return newSelectedSeats;
        });
    };

    const [busLayoutData, setBusLayoutData] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleShowBusSeat = (busType, busId, main_boarding_point_id, main_droping_point_id) => {
        // Reset selected seats when switching buses
        if (selectedBusId !== busId) {
            setSelectedLowerSeats([]);
            setSelectedSeatBusId(busId);
            setSelectedUpperSeats([]); // Assuming you have a similar state for upper seats
            setSelectedLowerSeatPrice([]);
            setSelectedUpperSeatPrice([]);
            setSelectedUpperSeat([]);
            setSelectedLowerSeat([]);
            setImageSrcLower({}); // Reset image sources for seats
            setImageSrcUpper({});
            setSelectedDropingValue({});
            setSelectedBoadingValue({})
            setTotalPrice(0)
        }

        setSelectedBusId(busId);
        setBusTypeID(busType);
        setShowSeats(true);
        setBusID(busId);
        busLayoutAPI(busId);
        BusWiseBoardingDroppingPoints(busId, main_boarding_point_id, main_droping_point_id);
        console.log(busId);
    };


    const handleHideBusSeat = () => {
        setOpen(false);
        setSelectedSeatBusId(null);
        setSelectedLowerSeats([])
        setSelectedBusId(null);
        setSelectedSeatBusId(null);
        setSelectedPoliciesBusId(null);
        setSelectedReviewBusId(null);
        setSelectedAmenitiesBusId(null)
        setShowSeats(false);
        setTotalPrice(0);
        setSelectedUpperSeat([]);
        setSelectedLowerSeat([]);
        setSelectedDropingValue({});
        setSelectedBoadingValue({})
    };

    const handleShowAmenities = (busId) => {
        setAmenitiesOpen(true);
        setSelectedBusId(busId);
        setSelectedAmenitiesBusId(busId);
    };

    const handleHideAmenities = (busId) => {
        setAmenitiesOpen(false);
        setSelectedBusId(busId);
        setSelectedAmenitiesBusId(null);
    };
    const handleShowReview = (busId) => {
        setReviewOpen(true);
        setSelectedReviewBusId(busId)
        setSelectedBusId(busId);
    };

    const handleHideReview = (busId) => {
        setReviewOpen(false);
        setSelectedReviewBusId(null)
        setSelectedBusId(busId);
    };
    const handleShowBookingPolicies = (busId) => {
        setBookingPolicies(true);
        setSelectedBusId(busId);
        setSelectedPoliciesBusId(busId)
    };

    const handleHideBookingPolicies = (busId) => {
        setBookingPolicies(false);
        setSelectedBusId(busId);
        setSelectedPoliciesBusId(null)
    };

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                {...other}
                style={{
                    overflowY: 'auto',
                    maxHeight: '400px',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#1333a7 white',
                }}>
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const [filters, setFilters] = useState({
        departureTime: {
            before6am: false,
            sixAmTo12pm: false,
            twelvePmTo6pm: false,
            after6pm: false,
        },
        busTypes: {
            ac: false,
            nonAc: false,
            acSeater: false,
            acSleeper: false,
            acSemiSleeper: false,
        },
        pickupTimeSurat: {
            earlyMorning: false,
            morning: false,
        },
        dropoffTimeJetpur: {
            earlyMorning: false,
            morning: false,
        },
        operatorRating: {
            five: false,
            four: false,
            three: false,
            two: false,
            one: false,
        },
    });
    // const handleCheckboxChange = (facilityName) => {
    //     setSelectedAmenities((prevSelected) => {
    //         if (prevSelected.includes(facilityName)) {
    //             return prevSelected.filter(item => item !== facilityName);
    //         } else {
    //             return [...prevSelected, facilityName];
    //         }
    //     });
    // };

    const handleCheckboxChange = (facilityName) => {
        setSelectedAmenities((prevSelected) => {
            if (prevSelected.includes(facilityName)) {
                // If already selected, remove it
                return prevSelected.filter(item => item !== facilityName);
            } else {
                // Otherwise, add it to the selected amenities
                return [...prevSelected, facilityName];
            }
        });
    };

    const handleCheckboxBusTypeAc = (type) => {
        setSelectedBusTypeAc([type]);
    };
    const handleCheckboxBusType = (type) => {
        setSelectedBusType([type]);
    };

    const seatHoldAPI = async (item) => {
        const selectedTotalSeat = selectedUpperSeats.concat(selectedLowerSeats)
        let data = new FormData();
        data.append('user_id', localStorage.getItem('UserID'))
        data.append('bus_id', item?.id)
        for (let i = 0; i < selectedTotalSeat.length; i++) {
            data.append(`seat_number[${i}]`, selectedTotalSeat[i]);
        }
        try {
            await axios.post("ticket_booking_hold", data, {
            }).then((res) => {
                if (res.data.success == true) {
                    toast.success(res.data.message);
                    if (res.data.message == 'Successfully hold.') {
                        const timerStart = Date.now();
                        console.log(timerStart);
                        history.push({
                            pathname: '/passange-details',
                            state: {
                                selectedUpperSeats,
                                selectedLowerSeats,
                                totalPrice,
                                selectedUpperSeatPrice,
                                selectedLowerSeatPrice,
                                bus_id: item?.id,
                                bus_name: item?.bus_name,
                                bus_ac: item?.bus_ac,
                                selectedboadingValue,
                                selecteddropingValue,
                                droping_time: item?.droping_time,
                                boarding_time: item?.boarding_time,
                                droping_date: item?.droping_date,
                                boarding_date: item?.boarding_date,
                                boarding_point_name: item?.boarding_point_name,
                                droping_point_name: item?.droping_point_name,
                                busIcon: busList?.image_url + item?.bus_image,
                                time_different: item?.time_different,
                                formattedDate,
                                to,
                                from,
                                timerStart,
                                main_boarding_point_id: item?.main_boarding_point_id,
                                main_droping_point_id: item?.main_droping_point_id
                            }
                        });
                    } else {
                        toast.success(res.data.message);
                    }

                } else {
                    toast.error(res.data.message || 'Invalid Message');
                    console.log(from);
                }
            })
        }
        catch (res) {
            toast.error(res.data.message);
        }
    }

    const handleConfirmSeat = (item) => {
        const newErrors = {};
        if (selectedLowerSeats.length == 0 && selectedUpperSeats.length == 0) {
            newErrors.selectedLowerSeats = 'Select any Seat';
            toast.error('Select any Seat');
        }
        else if (!selectedboadingValue?.boarding_id) {
            newErrors.selectedboadingValue = 'Select any Boarding Points';
            toast.error('Select any Boarding Points');
        }
        else if (!selecteddropingValue?.droping_id) {
            newErrors.selecteddropingValue = 'Select any Droping Points';
            toast.error('Select any Droping Points');
        }
        setErrors(newErrors);

        const isValid = Object.keys(newErrors).length === 0;

        if (isValid) {
            seatHoldAPI(item)
        }
    }

    const selectedBus = busList?.bus_array?.find(bus => bus.id === selectedBusId);
    return (
        <>
            <div>
                <Header />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="breadcrumbs--div">
                    <div className="container">
                        <div className="breadcrumb justify-content-between align-items-center my-3 text-capitalize">
                            <div className="datedestination">
                                <span>{formattedDate}</span>
                                <h5>
                                    <ul className="list-unstyled d-flex gap-2 align-items-center m-0">
                                        <li>{from?.city_name}</li>
                                        <li><MdOutlineArrowForwardIos /> </li>
                                        <li>{to?.city_name}</li>
                                    </ul>
                                </h5>
                            </div>
                            <div className="modifybtn">
                                {openDialog == false &&
                                    <Button variant="contained" onClick={handleOpenDialog}>Modify</Button>
                                }
                                {openDialog == true &&
                                    <div className="align-items-end d-flex dircetions flex-sm-nowrap flex-wrap gap-4 justify-content-center">
                                        <div className="form-group w-100">
                                            <div>
                                                <label htmlFor="from" className="fw-semibold text-gray fs-5">
                                                    <FaBusAlt />   From City
                                                </label>
                                            </div>
                                            <div>
                                                <span>{from?.city_name}</span>
                                            </div>
                                        </div>
                                        <div className="directimg mb-2 text-dark">
                                            <img
                                                src={dirIcon}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="form-group w-100">
                                            <div>
                                                <label htmlFor="to" className="fw-semibold text-gray fs-5">
                                                    <MdLocationOn />  To City
                                                </label>
                                            </div>
                                            <div>
                                                <span>{to?.city_name}</span>
                                            </div>
                                        </div>
                                        <div className="form-group w-100">
                                            <label htmlFor="date" className="fw-semibold text-gray mb-2">
                                                date
                                            </label>
                                            <div >
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={handleDateChange}
                                                    dateFormat="dd/MM/yyyy"
                                                    minDate={new Date()} //
                                                />
                                            </div>
                                        </div>
                                        <div className="btndivv mt-4 text-center">
                                            <Button variant="contained"
                                                onClick={() => {
                                                    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
                                                    searchwisebusList(formattedDate);
                                                    setOpenDialog(false);
                                                }}>
                                                Search
                                            </Button>
                                        </div>
                                    </div>
                                }
                                {timerActive && (
                                    <div>
                                        Time remaining: {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="searchpage--main mt-2">
                    <div class="searchpagediv">
                        <div class="container">
                            <div class="serchpagerow">
                                <div class="row">
                                    <div className="col-md-3">
                                        <div className="serchpage--filterdiv">
                                            <div className="srcfh-filter">
                                                <div className="titlediv">
                                                    <h5 className="fw-bolder text-capitalize">filters</h5>
                                                </div>
                                                <div className="filter--main">
                                                    {/* <div className="fltrtitle my-4">
                                                        <h5 className="text-capitalize mb-2 fw-semibold">departure time</h5>
                                                        <ul className="filterul list-unstyled">
                                                            {departureTime.map((label, index) => (
                                                                <li className="fltrli mb-2" key={index}>
                                                                    <div className="btn-group d-flex gap-2" role="group" aria-label="Basic checkbox toggle button group">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="btn-check1"
                                                                            id={`btncheck${index + 1}`}
                                                                            autoComplete="off"
                                                                            checked={Object.values(filters.departureTime)[index]}
                                                                            onChange={() => handleCheckboxChange('departureTime', Object.keys(filters.departureTime)[index])}
                                                                        />
                                                                        <label className="" htmlFor={`btncheck${index + 1}`}>
                                                                            {label}
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div> */}
                                                    <div className="fltrtitle my-4">
                                                        <h5 className="text-capitalize mb-2 fw-semibold">Amenities</h5>
                                                        <ul className="filterul list-unstyled d-flex flex-wrap column-gap-3 align-items-center">
                                                            {amenities.map((label, index) => (
                                                                <li className="fltrli mb-2" key={index}>
                                                                    <div>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`amenity-${index}`}
                                                                            className="btn-check"
                                                                            autoComplete="off"
                                                                            checked={selectedAmenities.includes(label.facility_name)}
                                                                            onChange={() => handleCheckboxChange(label.facility_name)}
                                                                            style={{ display: "none" }} // Hides the default checkbox
                                                                        />
                                                                        <label
                                                                            htmlFor={`amenity-${index}`}
                                                                            className="btn-group d-flex gap-2 btn btn-light"
                                                                            style={{ border: "1px solid gray", cursor: "pointer", alignItems: "center" }}
                                                                        >
                                                                            <span style={{ fontSize: "16px" }}>{label.facility_name}</span>
                                                                            {label.image && (
                                                                                <img
                                                                                    src={busList?.image_url + label?.image}
                                                                                    alt={label.facility_name}
                                                                                    style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                                                                                />
                                                                            )}
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                    <div className="fltrtitle my-4">
                                                        <h5 className="text-capitalize mb-2 fw-semibold">bus types</h5>
                                                        <ul className="filterul list-unstyled d-flex flex-wrap column-gap-3 align-items-center">
                                                            {busAcType.map((label, index) => (
                                                                <li className="fltrli mb-2" key={index}>
                                                                    <div>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`bustype-ac-${index}`}
                                                                            className="btn-check"
                                                                            autoComplete="off"
                                                                            checked={selectedBusTypeAc.includes(label.type)}
                                                                            onChange={() => handleCheckboxBusTypeAc(label.type)}
                                                                            style={{ display: "none" }} // Hides the default checkbox
                                                                        />
                                                                        <label
                                                                            htmlFor={`bustype-ac-${index}`}
                                                                            className="btn-group d-flex gap-2 btn btn-light"
                                                                            style={{ border: "1px solid gray", cursor: "pointer", alignItems: "center" }}
                                                                        >
                                                                            <span style={{ fontSize: "16px" }}>{label.type}</span>
                                                                            {label.image && (
                                                                                <img
                                                                                    src={label?.image}
                                                                                    alt={label.type}
                                                                                    style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                                                                                />
                                                                            )}
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                            {busType.map((label, index) => (
                                                                <li className="fltrli mb-2" key={index}>
                                                                    <div>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`bustype-${index}`}
                                                                            className="btn-check"
                                                                            autoComplete="off"
                                                                            checked={selectedBusType.includes(label.type)}
                                                                            onChange={() => handleCheckboxBusType(label.type)}
                                                                            style={{ display: "none" }} // Hides the default checkbox
                                                                        />
                                                                        <label
                                                                            htmlFor={`bustype-${index}`}
                                                                            className="btn-group d-flex gap-2 btn btn-light"
                                                                            style={{ border: "1px solid gray", cursor: "pointer", alignItems: "center" }}
                                                                        >
                                                                            <span style={{ fontSize: "16px" }}>{label.type}</span>
                                                                            {label.image && (
                                                                                <img
                                                                                    src={label?.image}
                                                                                    alt={label.type}
                                                                                    style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                                                                                />
                                                                            )}
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    {/* <div className="fltrtitle my-4">
                                                        <h5 className="text-capitalize mb-2 fw-semibold">Pick-up time (Surat)</h5>
                                                        <ul className="filterul list-unstyled d-flex flex-wrap column-gap-3 align-items-center">
                                                            {pickUpTime.map((label, index) => (
                                                                <li className="fltrli mb-2" key={index}>
                                                                    <div className="btn-group d-flex gap-2" role="group" aria-label="Basic checkbox toggle button group">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="btn-check"
                                                                            autoComplete="off"
                                                                            checked={Object.values(filters.busTypes)[index]}
                                                                            onChange={() => handleCheckboxChange('pickUpTime', Object.keys(filters.busTypes)[index])}
                                                                        />
                                                                        <label className="btn btn-outline-secondary" htmlFor={`bus${index}`}>
                                                                            {pickUpTimeImages[index] && (
                                                                                <img src={pickUpTimeImages[index]} alt="" className="img-fluid" />
                                                                            )}
                                                                            {label}
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div> */}

                                                    {/* <div className="fltrtitle my-4">
                                                        <h5 className="text-capitalize mb-2 fw-semibold">Operator Rating</h5>
                                                        <ul className="filterul list-unstyled d-flex flex-wrap column-gap-3 align-items-center">
                                                            {operatorRating.map((label, index) => (
                                                                <li className="fltrli mb-2" key={index}>
                                                                    <div className="btn-group d-flex gap-2" role="group" aria-label="Basic checkbox toggle button group">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="btn-check"
                                                                            autoComplete="off"
                                                                            checked={Object.values(filters.busTypes)[index]}
                                                                            onChange={() => handleCheckboxChange('operatorRating', Object.keys(filters.busTypes)[index])}
                                                                        />
                                                                        <label className="btn btn-outline-secondary" htmlFor={`bus${index}`}>
                                                                            {opratorRatingImages[index] && (
                                                                                <img src={opratorRatingImages[index]} alt="" className="img-fluid" width={'15px'} height={"15px"} />
                                                                            )}
                                                                            {label}
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {busList?.bus_array?.length == 0 ?

                                        <div className="col-md-9">
                                            <div className="bus-list-div">
                                                <div className="buslisttitle mb-4">
                                                    <Alert severity="warning">
                                                        <AlertTitle>Warning</AlertTitle>
                                                        <h5 className="text-capitalize">
                                                            This Route Shortly Available.
                                                        </h5>
                                                    </Alert>
                                                    {/* <strong>80 buses</strong> found */}
                                                    {/* < p > This Route Shortly Available.</p> */}
                                                </div>

                                            </div>
                                        </div>

                                        :
                                        <div className="col-md-9">
                                            <div className="bus-list-div">
                                                {busList?.bus_array?.map((item, index) => (
                                                    <div className="busrcrd--rows d-flex flex-column row-gap-4 mt-4" >
                                                        <div className="buslist--card card shadow-hover border-hover-none pt-3">
                                                            <div className="d-flex flex-column gap-2">
                                                                <div className="d-flex justify-content-between align-items-center busnmflex px-4">
                                                                    <div className="busname--icons">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <img src={busList?.image_url + item?.bus_image} alt="" style={{ height: '60px', width: '60px', borderRadius: '100%', border: '1px solid black' }} className="img-fluid" />
                                                                            <div>
                                                                                <h5 className="m-0 me-2 fw-semibold">{item?.bus_name}</h5>
                                                                                <p className="m-0 me-2 fw-semibold">{item?.bus_ac}  {item?.bus_type == 0 ? 'Seater' : item?.bus_type == 1 ? 'Sleeper' : 'Seater / Sleeper'}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div  >
                                                                        <p className="fw-medium m-0" style={{ fontSize: '17px', color: 'rgb(108 42 127)' }} >{item?.total_seat} Seat</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row px-4 align-items-center">
                                                                    <div className="col-md-6">
                                                                        <div className="d-flex justify-content-between align-items-center bustimeflex">
                                                                            <div className="bustimediv">
                                                                                <h6 className="fw-semibold mb-1">{item?.boarding_time}</h6>
                                                                                <p className="fw-medium m-0" style={{ color: 'rgb(108 42 127)', fontSize: '17px' }}>{item?.boarding_point_name}</p>
                                                                                <p className="fw-medium m-0">{item?.boarding_date}</p>
                                                                            </div>
                                                                            <div className="bustimediv flxbasissdiv">
                                                                                <span className="border--span"></span>
                                                                                <p className="bustotalhours fw-medium m-0">{item?.time_different}</p>
                                                                            </div>
                                                                            <div className="bustimediv">
                                                                                <h6 className="fw-semibold mb-1">{item?.droping_time}</h6>
                                                                                <p className="fw-medium m-0" style={{ color: 'rgb(108 42 127)', fontSize: '17px' }}>{item?.droping_point_name}</p>
                                                                                <p className="fw-medium m-0">{item?.droping_date}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="tcktpricediv text-end">
                                                                            <h4 className="fw-bold m-0">₹{item?.bus_price}</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="features--div">
                                                                    <ul className="nav featurenav nav-tabs px-4 text- capitalize align-items-center justify-content-end border-bottom-1">
                                                                        {item?.amenities?.length > 0 && (
                                                                            <li className="nav-item fs-6" style={{ borderRight: "1px solid gray", cursor: "pointer", color: "rgb(108, 42, 127)" }} >
                                                                                {amenitiesOpen && selectedAmenitiesBusId === item.id ? (
                                                                                    <a onClick={() => handleHideAmenities(item.id)}>
                                                                                        Amenities  <MdOutlineKeyboardArrowUp className="fs-3" />
                                                                                    </a>
                                                                                ) : (
                                                                                    <a onClick={() => handleShowAmenities(item.id)} >
                                                                                        Amenities <MdOutlineKeyboardArrowDown className="fs-3" />
                                                                                    </a>
                                                                                )}
                                                                            </li>
                                                                        )}

                                                                        {item?.review?.length > 0 && (
                                                                            <li className="nav-item" style={{ marginLeft: '10px', borderRight: "1px solid gray", cursor: "pointer", color: "rgb(108, 42, 127)" }}>
                                                                                {reviewOpen && selectedReviewBusId === item.id ? (
                                                                                    <a onClick={() => handleHideReview(item.id)} >
                                                                                        Review  <MdOutlineKeyboardArrowUp className="fs-3" />
                                                                                    </a>
                                                                                ) : (
                                                                                    <a onClick={() => handleShowReview(item.id)} style={{ minHeight: "10px" }} >
                                                                                        Review <MdOutlineKeyboardArrowDown className="fs-3" />
                                                                                    </a>
                                                                                )}
                                                                            </li>
                                                                        )}

                                                                        <li className="nav-item" style={{ marginLeft: '10px', cursor: "pointer", color: "rgb(108, 42, 127)" }} >
                                                                            {bookingPolicies && selectedPoliciesBusId === item.id ? (
                                                                                <a onClick={() => handleHideBookingPolicies(item.id)} >
                                                                                    Cancellation Policy  <MdOutlineKeyboardArrowUp className="fs-3" />
                                                                                </a>
                                                                            ) : (
                                                                                <a onClick={() => handleShowBookingPolicies(item.id)} style={{ minHeight: "10px" }} >
                                                                                    Cancellation Policy <MdOutlineKeyboardArrowDown className="fs-3" />
                                                                                </a>
                                                                            )}
                                                                        </li>
                                                                        <li className="nav-item" >
                                                                            {selectedSeatBusId !== item.id ? (
                                                                                <Button onClick={() => handleShowBusSeat(item.bus_type, item.id, item.main_boarding_point_id, item.main_droping_point_id)} variant="contained">
                                                                                    View Seat
                                                                                </Button>
                                                                            ) : (
                                                                                <Button onClick={handleHideBusSeat} variant="contained">
                                                                                    Hide Seats
                                                                                </Button>
                                                                            )}
                                                                        </li>
                                                                        {/*                                                                     
                                                                    <li className="nav-item" >
                                                                        {selectedBusId !== item.id ?
                                                                            <Button onClick={() => handleShowBusSeat(item.bus_type, item.id)} variant="contained">
                                                                                View Seat
                                                                            </Button> :
                                                                            <Button onClick={handleHideBusSeat} variant="contained">
                                                                                Hide Seats
                                                                            </Button>
                                                                        }
                                                                    </li> */}
                                                                    </ul>
                                                                    {/* {selectedBusId === item.id && amenitiesOpen && */}
                                                                    {selectedAmenitiesBusId === item.id && amenitiesOpen && (
                                                                        <div className="amenities-tab p-3 d-flex border-top-1">
                                                                            {selectedBus?.amenities?.map((ami, index) => (
                                                                                <div >
                                                                                    <div className="d-flex gap-2 align-items-center">
                                                                                        <img src={busList?.image_url + ami?.image} alt="" className="img-fluid" style={{ maxWidth: "25px" }} />
                                                                                        <h5 className="m-0 me-4 fs-6">{ami?.ameniti_name}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}

                                                                    {/* {selectedBusId === item.id && reviewOpen && */}
                                                                    {selectedReviewBusId === item.id && reviewOpen && (
                                                                        <>
                                                                            {selectedBus?.review?.map((rev, index) => (
                                                                                <div className="amenities-tab p-3 d-flex border-top-1">
                                                                                    <div >
                                                                                        <div className="d-flex mb-2" style={{ width: 'auto', justifyContent: 'space-between' }}>
                                                                                            <div className="busname--icons">
                                                                                                <div className="d-flex align-items-center gap-2">
                                                                                                    <span className="user-review"><FaUserAlt color="rgb(108, 42, 127)" /></span>
                                                                                                    <div className="d-flex flex-column">
                                                                                                        <h5 className="m-0 me-2 fw-semibold">{rev?.user_name}</h5>
                                                                                                        <div className="date"><span>{rev?.date}</span></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="">
                                                                                                <div className=" border border-dark p-1 rounded d-flex  align-items-center">
                                                                                                    <IoStarSharp style={{ color: "rgb(108, 42, 127)", fontSize: "20px", paddingRight: "5px" }} />
                                                                                                    <span >
                                                                                                        {rev?.rating}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <span className=" ml-4 loc text-body-tertiary fw-semibold">{rev?.msg}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                    {selectedPoliciesBusId === item.id && bookingPolicies && item.cancellation_policy && (
                                                                        // {selectedBusId === item.id && bookingPolicies && busLayoutData?.BusLayoutData?.length > 0 &&
                                                                        <div className="amenities-tab p-3 d-flex border-top-1">
                                                                            <div key={index}>
                                                                                <div className="d-flex gap-2 align-items-center">
                                                                                    <span dangerouslySetInnerHTML={{ __html: selectedBus?.cancellation_policy }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <div className="tab-content">
                                                                        {selectedBusId === item.id && showSeats && busLayoutData?.BusLayoutData?.length > 0 &&
                                                                            <>
                                                                                <div className="mt-4 p-4 table-responsive">
                                                                                    <div>
                                                                                        <div className="row">
                                                                                            <div className="column1">
                                                                                                <div className="d-flex gap-4">
                                                                                                    {busTypeID == 2 && busLayoutData?.BusLayoutData && busLayoutData.BusLayoutData.length > 0 &&
                                                                                                        <>
                                                                                                            {/* seat sofa */}
                                                                                                            <form onChange={e => busLayoutData(e)}>
                                                                                                                <ol className="cabin fuselage" style={{ padding: "0" }}>
                                                                                                                    <div className="d-flex justify-content-center gap-4 align-items-center my-3" style={{ maxHeight: "60px" }}>
                                                                                                                        {busLayoutData?.BusLayoutData[0]?.driver_direction == 1 && <GiSteeringWheel style={{ fontSize: "3.5rem", color: "rgba(19, 51, 167, 1)" }} />}
                                                                                                                        <li> <span className="fs-3"> Lower Deck</span></li>
                                                                                                                        {busLayoutData?.BusLayoutData[0]?.driver_direction == 0 && <GiSteeringWheel style={{ fontSize: "3.5rem", color: "rgba(19, 51, 167, 1)" }} />}
                                                                                                                    </div>
                                                                                                                    {busLayoutData?.BusLayoutData[0].lower_layout.map((row, rowIndex) => (
                                                                                                                        <div key={rowIndex} className="d-flex" style={{ cursor: "pointer", textAlign: "center" }}>
                                                                                                                            {row.map((seat, seatIndex) => (
                                                                                                                                <React.Fragment key={seatIndex}>
                                                                                                                                    {seat.seat_number === '' ? (
                                                                                                                                        <span className="seating-none"></span>
                                                                                                                                    ) : (
                                                                                                                                        <Tooltip title={seat.is_booked === true ? '' : `₹${seat.seat_price}`} placement="top-start" arrow>
                                                                                                                                            <span style={{ color: seat.is_booked === false ? 'black' : 'white' }}>
                                                                                                                                                ₹{seat.is_booked === false ? seat.seat_price : ""}
                                                                                                                                            </span>
                                                                                                                                            <div className={`seat-container ${seat.is_booked === true ? 'disabled' : ''}`} onClick={() => {
                                                                                                                                                if (seat.is_booked == false) {
                                                                                                                                                    handleClickLowerSeat(seat.seat_number);
                                                                                                                                                }
                                                                                                                                            }}  >
                                                                                                                                                <div >
                                                                                                                                                    <img src={seat.gender === 'FEMALE' ? seatPink : seat.is_booked == true ? seatBlack : seat.is_booked == true ? seatBlack : imageSrcLower[seat.seat_number] || seatEmpty} width='10%' height='10%' className="seating-seat-lower" style={{ cursor: seat.gender === 'FEMALE' || seat.is_booked == true ? 'not-allowed' : 'pointer' }}></img>
                                                                                                                                                    <span className="seat-number-onlyseat" >{seat.seat_number}</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </Tooltip>
                                                                                                                                    )}
                                                                                                                                </React.Fragment>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                    ))}

                                                                                                                </ol>
                                                                                                            </form>
                                                                                                            <form onChange={e => busLayoutData(e)}>
                                                                                                                <ol className="cabin fuselage" style={{ padding: "0" }}>
                                                                                                                    <div className="d-flex justify-content-center gap-4 align-items-center my-4 mr-4 " style={{ maxHeight: "90px" }}>
                                                                                                                        <li> <span className="fs-3"> Upper Deck</span></li>
                                                                                                                    </div>
                                                                                                                    {busLayoutData?.BusLayoutData[0].upper_layout.map((row, rowIndex) => (
                                                                                                                        <div key={rowIndex} className="d-flex justify-content-center" style={{ cursor: "pointer" }}>
                                                                                                                            {row.map((seat, seatIndex) => (
                                                                                                                                <React.Fragment key={seatIndex}>
                                                                                                                                    {seat.seat_number === '' ? (
                                                                                                                                        <span className="seat-none"></span>
                                                                                                                                    ) : (
                                                                                                                                        <Tooltip title={seat.is_booked === true ? '' : `₹${seat.seat_price}`} placement="top-start" arrow>
                                                                                                                                            <div className={`seat ${seat.is_booked === true ? 'disabled' : ''}`}
                                                                                                                                                style={{
                                                                                                                                                    border: seat.gender === 'FEMALE' && seat.is_booked == true ? '2px solid pink' : seat.is_booked == true ? '2px solid black' : selectedUpperSeats.includes(seat.seat_number) ? '2px solid #792C8F' : '',
                                                                                                                                                    cursor: seat.gender === 'FEMALE' || seat.is_booked == true ? 'not-allowed' : 'pointer'
                                                                                                                                                }} onClick={() => {
                                                                                                                                                    if (seat.is_booked == false) {
                                                                                                                                                        handleClickUpperSeat(seat.seat_number);
                                                                                                                                                    }
                                                                                                                                                }}>
                                                                                                                                                <span className="text-black fs-6">{seat.seat_number}</span>
                                                                                                                                                <label
                                                                                                                                                    htmlFor={seat.seat_number}
                                                                                                                                                    className="text-white  d-flex align-items-center text-center"
                                                                                                                                                    style={{ fontSize: "0.70rem", backgroundColor: seat.gender === 'FEMALE' && seat.is_booked == true ? 'pink' : seat.is_booked === true ? 'black ' : selectedUpperSeats.includes(seat.seat_number) ? '#792C8F' : '', cursor: "pointer" }}
                                                                                                                                                    aria-disabled={seat.is_booked === true}
                                                                                                                                                >  <span style={{ color: seat.is_booked === true ? 'black' : 'white' }}>
                                                                                                                                                        ₹{seat.is_booked === false ? seat.seat_price : ""}
                                                                                                                                                    </span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </Tooltip>
                                                                                                                                    )}
                                                                                                                                </React.Fragment>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                    ))}
                                                                                                                </ol>
                                                                                                            </form>
                                                                                                            <div>
                                                                                                                {/* <div>
                                                                                                                <h4 className="my-4">Price</h4>
                                                                                                                <span>Per Seat (Minimum Rate)</span>
                                                                                                                <h5>INR 720.00</h5>
                                                                                                                <div className="border border-bottom-secondary my-4"></div>
                                                                                                            </div> */}
                                                                                                                <div className="w-100 justify-content-between">
                                                                                                                    <div >
                                                                                                                        <h5 className="my-4">Know About Seats Type</h5>
                                                                                                                        <div className="d-flex justify-content-between" style={{ alignItems: "center", minWidth: "250px" }}>
                                                                                                                            <h6 className="text-secondary">Not Available</h6>
                                                                                                                            <div className="d-flex">
                                                                                                                                <img src={seatBlack} width='10%' height='10%' color="white" className="seating-seat"></img>
                                                                                                                                <div className="seat-gray">
                                                                                                                                    <span className="text-black fs-6"></span>
                                                                                                                                    <label ></label>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <div className="d-flex justify-content-between" style={{ alignItems: "center", minWidth: "250px" }}>
                                                                                                                            <h6 className="text-secondary" > Available</h6>
                                                                                                                            <div className="d-flex" >
                                                                                                                                <img src={seatEmpty} width='10%' height='10%' color="white" className="seating-seat"></img>
                                                                                                                                <div className="seat-available">
                                                                                                                                    <span className="text-black fs-6"></span>
                                                                                                                                    <label ></label>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <div className="d-flex justify-content-between" style={{ alignItems: "center", minWidth: "250px" }}>
                                                                                                                            <h6 className="text-secondary">  Selected</h6>
                                                                                                                            <div className="d-flex">
                                                                                                                                <img src={seatBlue} width='10%' height='10%' color="white" className="seating-seat"></img>
                                                                                                                                <div className="seat-selected">
                                                                                                                                    <span className="text-black fs-6"></span>
                                                                                                                                    <label ></label>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        {/* <div className="d-flex justify-content-between" style={{ alignItems: "center" }}>
                                                                                                                        <h6 className="text-secondary"> Available For Female</h6>
                                                                                                                        <div className="d-flex" >
                                                                                                                            <img src={seatPink} width='10%' height='10%' color="white" className="seating-seat"></img>
                                                                                                                            <div className="seat-female">
                                                                                                                                <span className="text-black fs-6"></span>
                                                                                                                                <label ></label>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div> */}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>

                                                                                                    }
                                                                                                    {busTypeID == 0 && busLayoutData?.BusLayoutData && busLayoutData.BusLayoutData.length > 0 &&
                                                                                                        <>
                                                                                                            {/* only seat */}
                                                                                                            <form onChange={e => busLayoutData(e)}>
                                                                                                                <ol className="cabin fuselage" style={{ padding: "0" }}>
                                                                                                                    <div className="d-flex justify-content-center gap-4 align-items-center my-3" style={{ maxHeight: "60px" }}>
                                                                                                                        {busLayoutData?.BusLayoutData[0]?.driver_direction == 1 && <GiSteeringWheel style={{ fontSize: "3.5rem", color: "rgba(19, 51, 167, 1)" }} />}
                                                                                                                        <li> <span className="fs-3"> Lower Deck</span></li>
                                                                                                                        {busLayoutData?.BusLayoutData[0]?.driver_direction == 0 && <GiSteeringWheel style={{ fontSize: "3.5rem", color: "rgba(19, 51, 167, 1)" }} />}
                                                                                                                    </div>
                                                                                                                    {busLayoutData.BusLayoutData[0].lower_layout.map((row, rowIndex) => (
                                                                                                                        <div key={rowIndex} className="d-flex" style={{ cursor: "pointer", textAlign: "center" }}>
                                                                                                                            {row.map((seat, seatIndex) => (
                                                                                                                                <React.Fragment key={seatIndex}>
                                                                                                                                    {seat.seat_number === '' ? (
                                                                                                                                        <span className="seating-none"></span>
                                                                                                                                    ) : (
                                                                                                                                        <Tooltip title={seat.is_booked === true ? '' : `₹${seat.seat_price}`} placement="top-start" arrow>
                                                                                                                                            <span style={{ color: seat.is_booked === false ? 'black' : 'white' }}>
                                                                                                                                                ₹{seat.is_booked === false ? seat.seat_price : ""}
                                                                                                                                            </span>
                                                                                                                                            <div className={`seat-container ${seat.is_booked === true ? 'disabled' : ''}`} onClick={() => {
                                                                                                                                                if (seat.is_booked == false) {
                                                                                                                                                    handleClickLowerSeat(seat.seat_number);
                                                                                                                                                }
                                                                                                                                            }}  >
                                                                                                                                                <div >
                                                                                                                                                    <img src={seat.gender === 'FEMALE' ? seatPink : seat.is_booked == true ? seatBlack : seat.is_booked == true ? seatBlack : imageSrcLower[seat.seat_number] || seatEmpty} width='10%' height='10%' className="seating-seat" style={{ cursor: seat.gender === 'FEMALE' || seat.is_booked == true ? 'not-allowed' : 'pointer' }} ></img>
                                                                                                                                                    <span className="seat-number-onlyseat text-center">{seat.seat_number}</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </Tooltip>
                                                                                                                                    )}
                                                                                                                                </React.Fragment>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                    ))}

                                                                                                                </ol>
                                                                                                            </form>
                                                                                                            <form onChange={e => busLayoutData(e)}>
                                                                                                                <ol className="cabin fuselage" style={{ padding: "0" }}>
                                                                                                                    <div className="d-flex justify-content-center gap-4 align-items-center my-3" style={{ minHeight: "55px" }}>
                                                                                                                        <li> <span className="fs-3"> Upper Deck</span></li>
                                                                                                                    </div>
                                                                                                                    {busLayoutData.BusLayoutData[0].upper_layout.map((row, rowIndex) => (
                                                                                                                        <div key={rowIndex} className="d-flex" style={{ cursor: "pointer", textAlign: "center" }}>
                                                                                                                            {row.map((seat, seatIndex) => (
                                                                                                                                <React.Fragment key={seatIndex}>
                                                                                                                                    {seat.seat_number === '' ? (
                                                                                                                                        <span className="seating-none"></span>
                                                                                                                                    ) : (
                                                                                                                                        <Tooltip title={seat.is_booked === true ? '' : `₹${seat.seat_price}`} placement="top-start" arrow>
                                                                                                                                            <span style={{ color: seat.is_booked === false ? 'black' : 'white' }}>
                                                                                                                                                ₹{seat.is_booked === false ? seat.seat_price : ""}
                                                                                                                                            </span>
                                                                                                                                            <div className={`seat-container ${seat.is_booked === true ? 'disabled' : ''}`} onClick={() => {
                                                                                                                                                if (seat.is_booked == false) {
                                                                                                                                                    handleClickUpperSeat(seat.seat_number);
                                                                                                                                                }
                                                                                                                                            }}  >
                                                                                                                                                <div >
                                                                                                                                                    <img src={seat.gender === 'FEMALE' && seat.is_booked == true ? seatPink : seat.is_booked == true ? seatBlack : imageSrcUpper[seat.seat_number] || seatEmpty} width='10%' height='10%' className="seating-seat" style={{ cursor: seat.gender === 'FEMALE' || seat.is_booked == true ? 'not-allowed' : 'pointer' }} ></img>
                                                                                                                                                    <span className="seat-number-onlyseat text-center">{seat.seat_number}</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </Tooltip>
                                                                                                                                    )}
                                                                                                                                </React.Fragment>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                    ))}
                                                                                                                </ol>
                                                                                                            </form>
                                                                                                            <div>
                                                                                                                {/* <div>
                                                                                                                <h4 className="my-4">Price</h4>
                                                                                                                <span>Per Seat (Minimum Rate)</span>
                                                                                                                <h5>INR 720.00</h5>
                                                                                                                <div className="border border-bottom-secondary my-4"></div>
                                                                                                            </div> */}
                                                                                                                <div className="w-100 justify-content-between">
                                                                                                                    <div >
                                                                                                                        <h5 className="my-4">Know About Seats Type</h5>
                                                                                                                        <div className="d-flex justify-content-between" style={{ alignItems: "center", minWidth: "250px" }}>
                                                                                                                            <h6 className="text-secondary">Not Available</h6>
                                                                                                                            <div>
                                                                                                                                <img src={seatBlack} width='10%' height='10%' color="white" className="seating-seat"></img>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <div className="d-flex justify-content-between" style={{ alignItems: "center", minWidth: "250px" }}>
                                                                                                                            <h6 className="text-secondary" > Available</h6>
                                                                                                                            <div >
                                                                                                                                <img src={seatEmpty} width='10%' height='10%' color="white" className="seating-seat"></img>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <div className="d-flex justify-content-between" style={{ alignItems: "center", minWidth: "250px" }}>
                                                                                                                            <h6 className="text-secondary">  Selected</h6>
                                                                                                                            <div >
                                                                                                                                <img src={seatBlue} width='10%' height='10%' color="white" className="seating-seat"></img>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {/* <div>
                                                                                                                    <div className="d-flex justify-content-between" style={{ alignItems: "center" }}>
                                                                                                                        <h6 className="text-secondary"> Available For Female</h6>
                                                                                                                        <div >
                                                                                                                            <img src={seatPink} width='10%' height='10%' color="white" className="seating-seat"></img>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </div> */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                    {
                                                                                                        busTypeID == 1 && busLayoutData?.BusLayoutData && busLayoutData.BusLayoutData.length > 0 &&
                                                                                                        <>
                                                                                                            {/* only sofa  */}
                                                                                                            <form onChange={e => busLayoutData(e)}>
                                                                                                                <ol className="cabin fuselage" style={{ padding: "0" }}>
                                                                                                                    <div className="d-flex justify-content-center gap-4 align-items-center my-3" style={{ maxHeight: "60px" }}>
                                                                                                                        {busLayoutData?.BusLayoutData[0]?.driver_direction == 1 && <GiSteeringWheel style={{ fontSize: "3.5rem", color: "rgba(19, 51, 167, 1)" }} />}
                                                                                                                        <li> <span className="fs-3"> Lower Deck</span></li>
                                                                                                                        {busLayoutData?.BusLayoutData[0]?.driver_direction == 0 && <GiSteeringWheel style={{ fontSize: "3.5rem", color: "rgba(19, 51, 167, 1)" }} />}
                                                                                                                    </div>
                                                                                                                    {busLayoutData.BusLayoutData[0].lower_layout.map((row, rowIndex) => (
                                                                                                                        <div key={rowIndex} className="d-flex justify-content-center" style={{ cursor: "pointer" }}>
                                                                                                                            {row.map((seat, seatIndex) => (
                                                                                                                                <React.Fragment key={seatIndex}>
                                                                                                                                    {seat.seat_number === '' ? (
                                                                                                                                        <span className="seat-none"></span>
                                                                                                                                    ) : (
                                                                                                                                        <Tooltip title={seat.is_booked === true ? '' : `₹${seat.seat_price}`} placement="top-start" arrow>
                                                                                                                                            <div className={`seat ${seat.is_booked === true ? 'disabled' : ''}`}
                                                                                                                                                style={{
                                                                                                                                                    border: seat.gender === 'FEMALE' && seat.is_booked == true ? '2px solid pink' : seat.is_booked == true ? '2px solid black' : selectedLowerSeats.includes(seat.seat_number) ? '2px solid #792C8F' : '',
                                                                                                                                                    cursor: seat.gender === 'FEMALE' || seat.is_booked == true ? 'not-allowed' : 'pointer'
                                                                                                                                                }} onClick={() => {
                                                                                                                                                    if (seat.is_booked == false) {
                                                                                                                                                        handleClickLowerSeat(seat.seat_number);
                                                                                                                                                    }
                                                                                                                                                }}>

                                                                                                                                                <span className="text-black fs-6">{seat.seat_number}</span>
                                                                                                                                                <label
                                                                                                                                                    htmlFor={seat.seat_number}
                                                                                                                                                    className="text-white  d-flex align-items-center text-center"
                                                                                                                                                    style={{ fontSize: "0.70rem", backgroundColor: seat.gender === 'FEMALE' && seat.is_booked == true ? 'pink' : seat.is_booked === true ? 'black ' : selectedLowerSeats.includes(seat.seat_number) ? '#792C8F' : '', cursor: "pointer" }}
                                                                                                                                                    aria-disabled={seat.is_booked === true}
                                                                                                                                                >
                                                                                                                                                    <span style={{ color: seat.is_booked === true ? 'black' : 'white' }}>
                                                                                                                                                        ₹{seat.is_booked === false ? seat.seat_price : ""}
                                                                                                                                                    </span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </Tooltip>
                                                                                                                                    )}
                                                                                                                                </React.Fragment>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                    ))}

                                                                                                                </ol>
                                                                                                            </form>
                                                                                                            {/* Upper Deck */}
                                                                                                            <form onChange={e => busLayoutData(e)}>
                                                                                                                <ol className="cabin fuselage" style={{ padding: "0" }}>
                                                                                                                    <div className="d-flex justify-content-center gap-4 align-items-center my-4 mr-4 " style={{ maxHeight: "90px" }}>
                                                                                                                        <li> <span className="fs-3"> Upper Deck</span></li>
                                                                                                                    </div>

                                                                                                                    {busLayoutData.BusLayoutData[0].upper_layout.map((row, rowIndex) => (
                                                                                                                        <div key={rowIndex} className="d-flex justify-content-center" style={{ cursor: "pointer" }}>
                                                                                                                            {row.map((seat, seatIndex) => (
                                                                                                                                <React.Fragment key={seatIndex}>
                                                                                                                                    {seat.seat_number === '' ? (
                                                                                                                                        <span className="seat-none"></span>
                                                                                                                                    ) : (
                                                                                                                                        <Tooltip title={seat.is_booked === true ? '' : `₹${seat.seat_price}`} placement="top-start" arrow>
                                                                                                                                            <div className={`seat ${seat.is_booked === true ? 'disabled' : ''}`}
                                                                                                                                                style={{
                                                                                                                                                    border: seat.gender === 'FEMALE' && seat.is_booked == true ? '2px solid pink' : seat.is_booked == true ? '2px solid black' : selectedUpperSeats.includes(seat.seat_number) ? '2px solid #792C8F' : '',
                                                                                                                                                    cursor: seat.gender === 'FEMALE' || seat.is_booked == true ? 'not-allowed' : 'pointer'
                                                                                                                                                }} onClick={() => {
                                                                                                                                                    if (seat.is_booked == false) {
                                                                                                                                                        handleClickUpperSeat(seat.seat_number);
                                                                                                                                                    }
                                                                                                                                                }}>
                                                                                                                                                <span className="text-black fs-6">{seat.seat_number}</span>
                                                                                                                                                <label
                                                                                                                                                    htmlFor={seat.seat_number}
                                                                                                                                                    className="text-white  d-flex align-items-center text-center"
                                                                                                                                                    style={{ fontSize: "0.70rem", backgroundColor: seat.gender === 'FEMALE' && seat.is_booked == true ? 'pink' : seat.is_booked === true ? 'black ' : selectedUpperSeats.includes(seat.seat_number) ? '#792C8F' : '', cursor: "pointer" }}
                                                                                                                                                    aria-disabled={seat.is_booked === true}
                                                                                                                                                >   <span style={{ color: seat.is_booked === true ? 'black' : 'white' }}>
                                                                                                                                                        ₹{seat.is_booked === false ? seat.seat_price : ""}
                                                                                                                                                    </span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </Tooltip>
                                                                                                                                    )}
                                                                                                                                </React.Fragment>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                    ))}
                                                                                                                </ol>
                                                                                                            </form>
                                                                                                            <div>
                                                                                                                {/* <div>
                                                                                                                <h4 className="my-4">Price</h4>
                                                                                                                <span>Per Seat (Minimum Rate)</span>
                                                                                                                <h5>INR 720.00</h5>
                                                                                                                <div className="border border-bottom-secondary my-4"></div>
                                                                                                            </div> */}
                                                                                                                <div className="w-100 justify-content-between">
                                                                                                                    <div >
                                                                                                                        <h5 className="my-4">Know About Seats Type</h5>
                                                                                                                        <div className="d-flex justify-content-between" style={{ alignItems: "center", minWidth: "250px" }}>
                                                                                                                            <h6 className="text-secondary">Not Available</h6>
                                                                                                                            <div className="seat-gray">
                                                                                                                                <span className="text-black fs-6"></span>
                                                                                                                                <label ></label>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <div className="d-flex justify-content-between" style={{ alignItems: "center", minWidth: "250px" }}>
                                                                                                                            <h6 className="text-secondary" > Available</h6>
                                                                                                                            <div className="seat-available">
                                                                                                                                <span className="text-black fs-6"></span>
                                                                                                                                <label ></label>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <div className="d-flex justify-content-between" style={{ alignItems: "center", minWidth: "250px" }}>
                                                                                                                            <h6 className="text-secondary">  Selected</h6>
                                                                                                                            <div className="seat-selected">
                                                                                                                                <span className="text-black fs-6"></span>
                                                                                                                                <label ></label>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {/* <div>
                                                                                                                    <div className="d-flex justify-content-between" style={{ alignItems: "center" }}>
                                                                                                                        <h6 className="text-secondary"> Available For Female</h6>
                                                                                                                        <div className="seat-female">
                                                                                                                            <span className="text-black fs-6"></span>
                                                                                                                            <label ></label>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <Box className="board-droppagerow">
                                                                                        <Box className="borddrop--div">
                                                                                            <Tabs
                                                                                                value={value}
                                                                                                onChange={handleChange}
                                                                                                className="nav boarddrop--anv nav-tabs text-capitalize gap-3 align-items-center justify-content-center"
                                                                                                variant="fullWidth"
                                                                                            >
                                                                                                <Tab label="Boarding Point" id="tab-0" aria-controls="tabpanel-0" />
                                                                                                <Tab label="Drop Point" id="tab-1" aria-controls="tabpanel-1" />
                                                                                            </Tabs>
                                                                                            <TabPanel value={value} index={0}>
                                                                                                <Box className="borddrop--content bg-white rounded-4 border-theme-opacity p-4">
                                                                                                    <RadioGroup value={selectedboadingValue?.boarding_id} onChange={handleSelectBoadingPoint}>
                                                                                                        {busWisePickupDropPoints?.boarding_array?.map((point, index) => (
                                                                                                            <Box key={index} className="bpdplist_item mb-2">
                                                                                                                <FormControlLabel
                                                                                                                    value={point?.boarding_id}
                                                                                                                    control={<Radio className="form-radio-input" />}
                                                                                                                    label={
                                                                                                                        <Box className="fw-medium form-checkk fs-20 d-flex gap-5 align-items-center">
                                                                                                                            <Box className="">
                                                                                                                                <strong className="time mt-4">{point?.boarding_time}</strong>
                                                                                                                            </Box>
                                                                                                                            <Box className="pointnm">
                                                                                                                                <Typography className="loc text-body-tertiary fw-semibold">{point?.boarding_sub_route_name}</Typography>
                                                                                                                                <Typography className="fs-16 text-body-tertiary fw-medium mb-0">{point?.boarding_address}</Typography>
                                                                                                                            </Box>
                                                                                                                        </Box>
                                                                                                                    }
                                                                                                                />
                                                                                                            </Box>
                                                                                                        ))}
                                                                                                    </RadioGroup>
                                                                                                </Box>
                                                                                            </TabPanel>
                                                                                            <TabPanel value={value} index={1}>
                                                                                                <Box className="borddrop--content bg-white rounded-4 border-theme-opacity p-4">
                                                                                                    <RadioGroup value={selecteddropingValue?.droping_id} onChange={handleSelectDropingPoint}>
                                                                                                        {busWisePickupDropPoints?.droping_array?.map((point, index) => (
                                                                                                            <Box key={index} className="bpdplist_item">
                                                                                                                <FormControlLabel
                                                                                                                    value={point?.droping_id}
                                                                                                                    control={<Radio className="form-radio-input" />}
                                                                                                                    label={
                                                                                                                        <Box className="fw-medium form-checkk fs-20 d-flex gap-5 align-items-center">
                                                                                                                            <Box className="">
                                                                                                                                <strong className="time mt-4">{point?.droping_time}</strong>
                                                                                                                            </Box>
                                                                                                                            <Box className="pointnm mb-1">
                                                                                                                                <Typography className="loc text-body-tertiary fw-semibold ">{point?.droping_sub_route_name}</Typography>
                                                                                                                                <Typography className="fs-16 text-body-tertiary fw-medium mb-0">{point?.droping_address}</Typography>
                                                                                                                                {/* <Typography className="loc text-body-tertiary fw-semibold">{point?.droping_address}</Typography>
                                                                                                                            <Typography className="fs-16 text-body-tertiary fw-medium mb-0">{point?.droping_sub_route_name}</Typography> */}
                                                                                                                            </Box>
                                                                                                                        </Box>
                                                                                                                    }
                                                                                                                />
                                                                                                            </Box>
                                                                                                        ))}
                                                                                                    </RadioGroup>
                                                                                                </Box>
                                                                                            </TabPanel>
                                                                                        </Box>
                                                                                    </Box>
                                                                                    <Box >
                                                                                        <Paper elevation={3} className="prcdtobookdiv p-5" sx={{ boxShadow: "none", background: "#cecdff33" }}>
                                                                                            <Box className="titlediv mb-3 mb-lg-4">
                                                                                                <Typography className="fs-4 fw-bold">Boarding & Dropping</Typography>
                                                                                            </Box>
                                                                                            <Box className="prcdtobook--content">
                                                                                                <Box className='d-flex gap-4 justify-content-between'>
                                                                                                    <Box className="pointnm">
                                                                                                        <Typography className="loc text-gray fw-semibold">{selectedboadingValue?.boarding_address}</Typography>
                                                                                                        <Typography className="fs-16 text-body-tertiary fw-medium mb-0">{selectedboadingValue?.boarding_sub_route_name}</Typography>
                                                                                                        <Typography className="time text-gray fw-semibold">{selectedboadingValue?.boarding_time}</Typography>
                                                                                                    </Box>
                                                                                                    <Box className="pointnm">
                                                                                                        <Typography className="loc text-gray fw-semibold">{selecteddropingValue?.droping_address}</Typography>
                                                                                                        <Typography className="fs-16 text-body-tertiary fw-medium mb-0">{selecteddropingValue?.droping_sub_route_name}</Typography>
                                                                                                        <Typography className="time text-gray fw-semibold">{selecteddropingValue?.droping_time}</Typography>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                                <Box className="slcdtotldiv mt-4">
                                                                                                    <Box className="d-flex justify-content-between align-items-center">
                                                                                                        <Box className="slctdstcountdiv">
                                                                                                            <Typography className="fw-bold fs-5" style={{ color: 'rgb(108, 42, 127)' }}>Selected Seat</Typography>
                                                                                                            <ul className="d-flex gap-2 list-unstyled mt-3">
                                                                                                                <span>Lower Seat :- {`(${selectedLowerSeat?.length})`}</span>
                                                                                                                {selectedLowerSeat.map((seat, index) => (
                                                                                                                    <li key={index} className="border-right-1 pe-3">
                                                                                                                        <strong>{seat}</strong>
                                                                                                                    </li>
                                                                                                                ))}
                                                                                                            </ul>

                                                                                                            <ul className="d-flex gap-2 list-unstyled mt-3">
                                                                                                                <span>Upper Seat :- {`(${selectedUpperSeat?.length})`}</span>
                                                                                                                {selectedUpperSeat.map((seat, index) => (
                                                                                                                    <li key={index} className="border-right-1 pe-3">
                                                                                                                        <strong>{seat}</strong>
                                                                                                                    </li>
                                                                                                                ))}
                                                                                                            </ul>

                                                                                                        </Box>
                                                                                                        <Box className="ttolpricdiv">
                                                                                                            <Typography className="fw-bold" variant="h4">INR {totalPrice}</Typography>
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>
                                                                                            <div className="btndivv mt-4 text-center">
                                                                                                <Button variant="contained" onClick={() => handleConfirmSeat(item)}
                                                                                                >
                                                                                                    Confirm Seat
                                                                                                </Button>
                                                                                            </div>
                                                                                        </Paper>
                                                                                    </Box>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}


                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        </>
    )
}


export default BusList